<template>
  <div class="departments-news-list">
    <div class="departments-news-list--col-3">
      <ListPressItem v-for="item in items" :key="item.id" :data="item" />
    </div>
    <PaginationComponent
      :first="6"
      :page="page"
      :total="Math.ceil(paginatorInfo.total / 6)"
      :totalItems="paginatorInfo.total"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import ListPressItem from "components/ListPressItem.vue";
import PaginationComponent from "components/PaginationComponent.vue";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    paginatorInfo: {
      type: Object,
      default: () => {},
    },
    page: {
      type: Number,
    },
  },
  components: {
    PaginationComponent,
    ListPressItem,
  },
  methods: {
    paginate(page) {
      this.$emit("paginate", page);
    },
  },
};
</script>

<style lang="stylus">
.departments-news-list {
  display flex
  flex-direction column
  align-items flex-start
  gap 30px
  &--col-3 {
    display: grid;
    grid-gap: 30px;
    max-width: var(--max-width);
    width: 100%;
    padding-top 30px
    border-top 2px solid #DAE5EF
    grid-template-columns: repeat(3, 1fr);
    +below(1024px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(728px) {
      grid-template-columns 1fr
    }
  }
}
</style>
