<template>
  <section class="ministry-department-open" v-if="department">
    <DepartmentCard big :data="department" />
    <a class="ministry-department-open__service" @click="openServiceModal(department.services)">
      <span>Список предоставляемых услуг</span>
    </a>
    <div class="ministry-department-open__article" v-if="department.description">
      <EditorJSComponent :text="department | getArticle" />
    </div>
    <NewsList
      v-if="news.length"
      @paginate="paginate"
      :items="news"
      :page="page"
      :paginator-info="paginatorInfo"
    />
  </section>
</template>

<script>
import DepartmentCard from "components/DepartmentCard.vue";
import NewsList from "./components/NewsList.vue";
import SUB_DEPARTMENTS_ITEM from "gql/queries/sub_departments_item.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ServiceModal from "components/modals/components/ServiceModal.vue";

export default {
  name: "MinistryDepartmentsPage",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: SUB_DEPARTMENTS_ITEM,
        variables: {
          id: parseInt(route.params.id),
          page: 1,
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("ministry/save", data);
          store.state.ministry.ministry_breadcrumbs_title = store.state.ministry.sub_departments_item.title;
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    openServiceModal(services) {
      this.$store.state._modals.push({
        component: ServiceModal,
        options: {
          data: services,
        },
      });
    },
    paginate(page) {
      this.page = page;
      this.fetch();
    },
    async fetch() {
      let variables = {
        id: parseInt(this.$route.params.id),
        page: this.page,
      };
      // history.pushState({}, null, this.getPushStateLink());
      await this.$apollo
        .query({
          query: SUB_DEPARTMENTS_ITEM,
          variables,
        })
        .then(({ data }) => {
          if (data) {
            this.$store.dispatch("ministry/save", data);
            this.$store.state.ministry.ministry_breadcrumbs_title =
              this.$store.state.ministry.sub_departments_item.title;
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    department() {
      return this.$store.state.ministry.sub_departments_item;
    },
    news() {
      return this.$store.state.ministry.news_paginate.data;
    },
    paginatorInfo() {
      return this.$store.state.ministry.news_paginate.paginatorInfo;
    },
  },
  metaInfo() {
    const meta = this.$store.state.ministry.sub_departments_item;
    return {
      title: meta?.meta_title || meta?.title,
      meta: [
        {
          name: "description",
          content: meta?.meta_description,
        },
        {
          name: "keywords",
          content: meta?.meta_keywords,
        },
      ],
    };
  },
  components: { EditorJSComponent, DepartmentCard, NewsList },
};
</script>

<style lang="stylus">
.ministry-department-open {
  display grid
  grid-gap 50px
  +below(1024px) {
    grid-gap 30px
  }

  &__service {
    font-weight 500
    font-size 1.125em
    line-height 28px
    color var(--dark)
    cursor pointer
  }

  &__article {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    margin -22px 0
  }
}
</style>
