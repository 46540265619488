<template>
  <section class="service-modal">
    <ul class="service-modal__text">
      <li v-for="(item, i) in data" :key="i">{{ item.title }}</li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "ServiceModal",
  props: {
    data: Array,
  },
};
</script>

<style lang="stylus">
.service-modal {
  background var(--white)
  padding 20px
  width 50%
  height 50%
  border-radius 10px
  z-index 1

  &__text {
    display flex
    flex-direction column
    gap 10px
    list-style auto
    padding 20px
  }
}
</style>
